import mixpanel from 'mixpanel-browser';

//TODO: verify RGBD for ignore dnt (if browser stop tracking)
mixpanel.init('e1fbab9ffea4d4c6600f08d9d57cd54d', { debug: false, ignore_dnt: true });

const mixpanelTrack = (name: string, properties?: Record<string, string | number | null>) => {
  if (!name || window === undefined) return;

  if (window?.ENVIRONMENT === 'production') {
    if (properties !== undefined) {
      mixpanel.track(name, properties);
    } else {
      mixpanel.track(name);
    }
  } else {
    if (properties !== undefined) {
      console.log('Mixpanel track', name, properties);
    } else {
      console.log('Mixpanel track', name);
    }
  }
};

export default mixpanelTrack;
